import React, { useState, useEffect } from 'react';
import { Switch, NavLink, Redirect, useLocation } from 'react-router-dom';

// Components
import PrivateRoute from '@/components/Shared/PrivateRoute';
import SetUpPictureDay from './set-up-picture-day';
import SellBeforePictureDay from './sell-before-picture-day';
import BoostBuyRate from './boost-buy-rate';
import RefundRequest from './refund-request';

const Support = () => {
  const location = useLocation();

  const [section, setSection] = useState('Set Up Picture Day');
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  const setSectionTitle = (path) => {
    const pathArr = path.split('/');
    const section = pathArr[pathArr.length - 1];

    if (section === 'set-up-picture-day' && section !== 'Set Up Picture Day') {
      setSection('Set Up Picture Day');
    }
    if (section === 'sell-before-picture-day' && section !== 'Sell Before Picture Day') {
      setSection('Sell Before Picture Day');
    }
    if (section === 'boost-your-buy-rates' && section !== 'Boost Your Buy Rates') {
      setSection('Boost Your Buy Rates');
    }
    if (section === 'refund-request' && section !== 'Refund Request Form') {
      setSection('Refund Request Form');
    }
  };

  const handleToggleSidebar = () => {
    setSidebarIsOpen(!sidebarIsOpen);
  };

  useEffect(() => {
    setSectionTitle(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <aside className="basis-3/12 md:basis-full sidebar-container">
        <header className="flex items-center justify-between sidebar-header">
          <h2 className="text-headline-sm" data-cy="resources-support-header">
            Support
          </h2>
          <button className="sidebar__mobile-action button button--outline" onClick={handleToggleSidebar}>
            {section}
          </button>
        </header>

        <nav className={`sidebar-navigation ${sidebarIsOpen ? 'sidebar-navigation--open' : ''}`} onClick={handleToggleSidebar}>
          <NavLink
            className="sidebar-navigation__item"
            to={`/support/set-up-picture-day`}
            data-cy="setup-picture-day-link"
            activeClassName="sidebar-navigation__item--active"
          >
            Set Up Picture Day
          </NavLink>
          <NavLink
            className="sidebar-navigation__item"
            to={`/support/sell-before-picture-day`}
            data-cy="sell-picture-day-link"
            activeClassName="sidebar-navigation__item--active"
          >
            Sell Before Picture Day
          </NavLink>
          <NavLink
            className="sidebar-navigation__item"
            to={`/support/boost-your-buy-rates`}
            data-cy="boost-buy-rates-link"
            activeClassName="sidebar-navigation__item--active"
          >
            Boost Your Buy Rates
          </NavLink>
          <a href="https://guide.photoday.io" className="sidebar-navigation__item" target="_blank" rel="noopener noreferrer" data-cy="user-guide-link">
            User Guide
          </a>
          <a
            href="https://www.photoday.io/troubleshoot"
            className="sidebar-navigation__item"
            target="_blank"
            rel="noopener noreferrer"
            data-cy="troubleshooting-link"
          >
            Customer Troubleshooting Guide
          </a>
          <a href="https://support.photoday.io/" className="sidebar-navigation__item" target="_blank" rel="noopener noreferrer" data-cy="support-link">
            Support FAQs
          </a>
        </nav>
      </aside>

      <section className="basis-9/12 md:basis-full resources-container">
        <Switch>
          <Redirect exact from="/support" to="/support/set-up-picture-day" />
          <PrivateRoute exact path="/support/set-up-picture-day" component={SetUpPictureDay} />
          <PrivateRoute exact path="/support/sell-before-picture-day" component={SellBeforePictureDay} />
          <PrivateRoute exact path="/support/boost-your-buy-rates" component={BoostBuyRate} />
          <PrivateRoute exact path="/support/refund-request" component={RefundRequest} />
        </Switch>
      </section>
    </>
  );
};

export default Support;
