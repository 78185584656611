import React, { useState, useEffect } from 'react';
import { Switch, NavLink, Redirect, useLocation } from 'react-router-dom';

// Components
import Sales from './Sales';
import Marketing from './Marketing';
import PrivateRoute from '@/components/Shared/PrivateRoute';

const Collateral = () => {
  const location = useLocation();

  const [section, setSection] = useState('Sales Kit');
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  const setSectionTitle = (path) => {
    const pathArr = path.split('/');
    const section = pathArr.pop();

    if (section === 'sales' && section !== 'Sales Kit') {
      setSection('Sales Kit');
    } else if (section === 'marketing' && section !== 'Marketing Kit') {
      setSection('Marketing Kit');
    }
  };

  const handleToggleSidebar = () => {
    setSidebarIsOpen(!sidebarIsOpen);
  };

  useEffect(() => {
    setSectionTitle(location.pathname);
  }, []);

  return (
    <>
      <aside className="basis-3/12 md:basis-full sidebar-container">
        <header className="flex items-center justify-between sidebar-header">
          <h2 className="text-headline-sm">Collateral</h2>
          <button className="sidebar__mobile-action button button--outline" onClick={handleToggleSidebar}>
            {section}
          </button>
        </header>

        <nav className={`sidebar-navigation ${sidebarIsOpen ? 'sidebar-navigation--open' : ''}`} onClick={handleToggleSidebar}>
          <NavLink className="sidebar-navigation__item" to={`/collateral/sales`} data-cy="sales-kit-nav" activeClassName="sidebar-navigation__item--active">
            Sales Kit
          </NavLink>
          <NavLink
            className="sidebar-navigation__item"
            to={`/collateral/marketing`}
            data-cy="marketing-kit-nav"
            activeClassName="sidebar-navigation__item--active"
          >
            Marketing Kit
          </NavLink>
        </nav>
      </aside>

      <section className="basis-9/12 md:basis-full resources-container">
        <Switch>
          <Redirect exact from="/collateral" to="/collateral/sales" />
          <PrivateRoute exact path="/collateral/sales" component={Sales} />
          <PrivateRoute exact path="/collateral/marketing" component={Marketing} />
        </Switch>
      </section>
    </>
  );
};

export default Collateral;
