import { createElement } from 'react';

import Gallery from './Gallery';
import './style.css';

const Skeleton = ({ tag: Tag = 'div', height = '250px', width = null, className, ...options }) => {
  const style = { height, ...(width ? { width } : {}) };

  return createElement(Tag, {
    ...options,
    className: ['shared__skeleton', className || ''].join(' '),
    style
  });
};

Skeleton.Gallery = Gallery;

export default Skeleton;
