import { Component } from 'react';
import { Link } from 'react-router-dom';

// Components
import ChecklistAccordion from './ChecklistAccordion';

// Images
import checklist_img1 from '@/assets/images/buy_rate_checklist_img1.jpg';
import checklist_img2 from '@/assets/images/buy_rate_checklist_img2.jpg';
import checklist_img3 from '@/assets/images/buy_rate_checklist_img3.jpg';
import checklist_img4 from '@/assets/images/buy_rate_checklist_img4.jpg';
import checklist_img5 from '@/assets/images/buy_rate_checklist_img5.jpg';

// Styles
import './boost_buy_rate.css';

const BoostBuyRate = () => {
  return (
    <div id="boost-buy-rate" data-cy="boost-buy-rate">
      <header className="flex items-center resources-container__header">
        <h2 className="text-headline-sm">Boost Your Buy Rates Checklist</h2>
      </header>

      <div className="panel flex items-center justify-between">
        <p className="m-0">You want to sell online. Parents want to buy online. Don't fret about buy rates. Just follow this checklist!</p>
        <a href="https://media.photoday.io/pdfs/boost_buy_rate.pdf" className="button button--medium" data-cy="buy-rate-pdf" download>
          Download PDF
        </a>
      </div>

      <ChecklistAccordion title="Set-up &amp; Pricing" dataCy="setup-checklist">
        <img className="panel-img" src={checklist_img1} alt="Checklist 1" />
        <ul className="list--bullet" data-cy="setup-list">
          <li>Make sure product and package pricing isn't too high or too low. Aim for just right!</li>
          <li>
            Structure packages to contain products that your customers want - and name packages to be clear and enticing.{' '}
            <a href="https://blog.photoday.io/2018/09/05/create-packages-that-work/" target="_blank" rel="noopener noreferrer">
              {' '}
              Read this{' '}
            </a>
          </li>
          <li>Create a gallery access code that is short and easy to remember.</li>
          <li>
            Use AdvancePay with an offer to drive sales before and during picture day. <Link to="/support/sell-before-picture-day">Read this</Link>
          </li>
          <li>
            Set up and start promoting your job early and often. <Link to="/support/sell-before-picture-day">Read this</Link>
          </li>
        </ul>
      </ChecklistAccordion>

      <ChecklistAccordion title="Promotion" dataCy="promotion-checklist">
        <img className="panel-img" src={checklist_img2} alt="Checklist 2" />
        <ul className="list--bullet" data-cy="promotion-list">
          <li>Create offers to drive the customer behavior you dream about: higher order values, earlier ordering, etc. </li>
          <li>
            Make sure that offers excite your customers and create urgency.{' '}
            <a
              href="https://blog.photoday.io/2018/05/16/8-ways-photographers-can-use-coupon-offers-to-increase-sales/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read this
            </a>
          </li>
          <li>
            Start selling before picture day with AdvancePay. <Link to="/support/sell-before-picture-day">Read this</Link>{' '}
          </li>
          <li>
            Set up a flyer that looks great and contains all the important stuff: picture day details (location, time, etc.), gallery access code, offer info
            and coupon code, AdvancePay details, and instructions for accessing their gallery!{' '}
          </li>
          <li>Email the flyer to the organization contact for distribution to parents.</li>
          <li>Follow up with your contact to make sure that the flyers have been sent to every parent.</li>
          <li>
            If possible, hang posters before picture day in a place that is visible to parents.{' '}
            <a href="https://blog.photoday.io/2018/08/01/new-marketing-tools-for-a-new-picture-day/" target="_blank" rel="noopener noreferrer">
              Read this
            </a>
          </li>
        </ul>
      </ChecklistAccordion>

      <ChecklistAccordion title="Picture Day" dataCy="picture-day-checklist">
        <img className="panel-img" src={checklist_img3} alt="Checklist 3" />
        <ul className="list--bullet" data-cy="picture-day-list">
          <li>
            Post signs with gallery access code and instructions near and around your picture day set-up - for all to see!{' '}
            <Link to="/collateral/marketing">See the Marketing Kit</Link>
          </li>
          <li>Make sure your staff is trained to hand out reminder cards to parents, babysitters, and kids. </li>
          <li>Take great photos. You already do this! </li>
          <li>Shoot multiple poses of each child - to give parents more photo options to purchase. </li>
        </ul>
      </ChecklistAccordion>

      <ChecklistAccordion title="Photo Sales" dataCy="photo-sales-checklist">
        <img className="panel-img" src={checklist_img4} alt="Checklist 4" />
        <ul className="list--bullet" data-cy="sales-list">
          <li>Time is money! Get your photos to parents as quickly as possible.</li>
          <li>Check that your watermark is the right size and in the right location.</li>
          <li>Be sure to have the correct artwork for the team, league, or school.</li>
          <li>Before you upload photos to PhotoDay, pull out any photos you don't want to share.</li>
          <li>
            {' '}
            Make photos easy to find by organizing them into folders. These folders become the searchable tags for your customers - so name them clearly!
          </li>
          <li>Publish your gallery and PhotoDay will immediately send a text message alert to everyone who texted in the access code. </li>
          <li>
            Follow up with the organization to let them know that the gallery is live. Provide an updated flyer - with gallery access and offer information - so
            the organization can send it out to parents.
          </li>
        </ul>
      </ChecklistAccordion>

      <ChecklistAccordion title="PhotoDay will handle the rest!" dataCy="photoday-checklist">
        <img className="panel-img" src={checklist_img5} alt="Bottom Panel" />
        <ul className="list--bullet" data-cy="photoday-list">
          <li>We send text messages to customers with a link to their gallery to view photos and purchase products. </li>
          <li>We constantly update our mobile app and web galleries to make sure the buying process is super quick and easy. </li>
          <li>Everything we do is designed to drive record buy rates and order values for your business!</li>
        </ul>
      </ChecklistAccordion>
    </div>
  );
};

export default BoostBuyRate;
