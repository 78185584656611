import { useEffect } from 'react';

// Redux
import { getFlyer } from '../../../actions';
import { useSelector, useDispatch } from 'react-redux';

// Plugins
import moment from 'moment';
import { useParams, useLocation } from 'react-router-dom-v5-compat';

// Helpers
import { Helmet } from 'react-helmet';
import { mediumDate } from '@/utils/displayFormats';

// Images
import flyer_open_graph from '@/assets/images/flyer_open_graph.jpg';

// Styles
import './style.css';
import '../Design/Layouts/Basic/style.css';
import '../Design/Layouts/Classic/style.css';
import '../Design/Layouts/ModernHorizontal/style.css';
import '../Design/Layouts/ModernVertical/style.css';

const GALLERY_URL = import.meta.env.VITE_GALLERY_URL;
const MY_PHOTODAY_URL = import.meta.env.VITE_MY_PHOTODAY_URL;
const MY_PHOTODAY_FLYER_URL = import.meta.env.VITE_MY_PHOTODAY_FLYER_URL;

const Preview = () => {
  const dispatch = useDispatch();
  const { flyerId } = useParams();
  const { pathname } = useLocation();
  const url = pathname.slice(1);

  const { flyer } = useSelector((state) => state.jobs);
  const job = flyer.job || { meta: {}, advancepay_offer: {} };
  const isPrivateJob = job.access_mode === 'access_per_subject';
  const accessCode = isPrivateJob ? '{{access_code}}' : job.access_code;
  const galleryUrl = job.gallery_v4 ? `${MY_PHOTODAY_URL}g/${accessCode}` : GALLERY_URL;

  const createMarkup = (markup) => {
    return { __html: markup.replace('"{{flyer_image}}"', flyer.image_url) };
  };

  const onResize = () => {
    const container = document.querySelector('.job-marketing-flyers__preview--v2');

    const maxWidth = 673;
    const innerWidth = window.innerWidth;

    if (container) {
      const scaleFactor = innerWidth < maxWidth ? (innerWidth - 20) / maxWidth : 1;

      container.style.transform = `scale(${scaleFactor})`;
    }
  };

  useEffect(() => {
    dispatch(getFlyer({ id: flyerId }));

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    onResize();
  });

  return (
    <>
      <Helmet>
        {/* Search Optimization */}
        <title>{`${job.meta.studio_name} | ${flyer.name}`}</title>
        <meta name="robots" content="all" />
        <meta name="author" content={`PhotoDay | ${job.meta.studio_name}`} />
        <link rel="canonical" href={`${MY_PHOTODAY_FLYER_URL}${url}`} />
        <meta name="description" content={flyer.body} />

        {/* Schema.org microdata */}
        <meta itemscope="" itemtype="http://schema.org/Article" />
        <meta itemprop="headline" content={`${job.meta.studio_name} | ${flyer.name}`} />
        <meta itemprop="description" content={flyer.body} />
        <meta itemprop="image" content={flyer.image_url || flyer_open_graph} />

        {/* Open Graph Protocol */}
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={`${job.meta.studio_name} | ${flyer.name}`} />
        <meta property="og:url" content={`${MY_PHOTODAY_FLYER_URL}${url}`} />
        <meta property="og:title" content={`${job.meta.studio_name} | ${flyer.name}`} />
        <meta property="og:description" content={flyer.body} />
        <meta property="og:image" content={flyer.image_url || flyer_open_graph} />

        {/* Twitter Cards */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={`${job.meta.studio_name} | ${flyer.name}`} />
        <meta name="twitter:creator" content="@photoday" />
        <meta name="twitter:url" content={`${MY_PHOTODAY_FLYER_URL}${url}`} />
        <meta name="twitter:title" content={`${job.meta.studio_name} | ${flyer.name}`} />
        <meta name="twitter:description" content={flyer.body} />
        <meta name="twitter:image" content={flyer.image_url || flyer_open_graph} />
        <style type="text/css">{flyer.layout_style}</style>
      </Helmet>

      {flyer?.flyers_version === 2 && (
        <section className="job-marketing-flyers__preview--v2">
          <div dangerouslySetInnerHTML={createMarkup(flyer.layout_markup)} />
        </section>
      )}

      {flyer?.flyers_version === 1 && (
        <section className="job-marketing-flyers__preview">
          <div className="job-marketing-flyers__preview-container">
            {flyer.image_url && (
              <figure className="job-marketing-flyers__preview-cover">
                <img className="job-marketing-flyers__preview-image" src={flyer.image_url} alt={flyer.name} />
              </figure>
            )}

            <hgroup className="job-marketing-flyers__preview-titles">
              <h1 className="job-marketing-flyers__preview-title">
                <strong>{flyer.name}</strong>
              </h1>
            </hgroup>

            <p className="font-semibold job-marketing-flyers__preview-paragraph">{flyer.body}</p>
            {flyer.show_job_date && flyer.flyer_offer_type !== 'post_picture' && (
              <p className="job-marketing-flyers__preview-subtitle">
                <span>{`Your picture date ${flyer.flyer_offer_type === 'advance_pay' ? 'is' : 'was'} on`} </span>
                <span className="font-semibold">{moment.utc(job.date).format('L')}</span>.
              </p>
            )}

            <aside className="job-marketing-flyers__preview-access">
              <span className="job-marketing-flyers__preview-code">
                {job.access_mode === 'access_public' ? 'Opt-In Code' : 'Access Code'}: {job.access_code}
              </span>
            </aside>

            {flyer.flyer_offer_type === 'advance_pay' ? <h3>How you can purchase credits:</h3> : <h3>Ways to get started:</h3>}

            <ol className="job-marketing-flyers__preview-how">
              <li>
                1.Text the {job.access_mode === 'access_public' ? 'Opt-In Code' : 'Access Code'} to <strong>{job.access_phone}</strong>
              </li>
              <li>
                2. Visit{' '}
                <a href={galleryUrl} target="_blank" rel="noopener noreferrer">
                  <b>
                    <i>{galleryUrl}</i>
                  </b>
                </a>
                {job.access_mode !== 'access_public' && (
                  <>{job.gallery_v4 ? <p>and login using your phone number.</p> : <p>and enter the access code to get started.</p>}</>
                )}
              </li>
            </ol>

            {flyer.flyer_offer_type && flyer.flyer_offer_type !== 'advance_pay' && (
              <>
                {flyer.offers.map((offer) => (
                  <article className="job-marketing-flyers__preview-coupon" key={offer.id}>
                    <div className="job-marketing-flyers__preview-coupon-code">
                      <span>Use Coupon code</span>
                      <h3>{offer.code}</h3>
                    </div>
                    <div className="job-marketing-flyers__preview-coupon-details">
                      <p className="font-semibold">
                        {offer.offer_type === 'shipping'
                          ? 'Get FREE SHIPPING '
                          : `Save ${
                              offer.offer_type === 'fixed_amount' ? `$${(offer.fixed_amount_cents / 100).toFixed(2)}` : `${offer.fixed_percentage || 0}%`
                            } ${offer.includes_free_shipping ? '+ FREE SHIPPING ' : ''}`}
                        {offer.min_order_price_cents && <span>with orders of ${(offer.min_order_price_cents / 100).toFixed(2)} or more!</span>}
                      </p>

                      {offer.max_redemptions && <p>Available only for the first {offer.max_redemptions}</p>}
                      {offer.expiration && (
                        <p>
                          Expires <span className="font-semibold">{mediumDate(offer.expiration)}</span> at 11:59pm PST.
                        </p>
                      )}
                    </div>
                  </article>
                ))}
              </>
            )}

            {flyer.flyer_offer_type === 'advance_pay' && job.advancepay_offer && (
              <article className="job-marketing-flyers__preview-coupon">
                <div className="job-marketing-flyers__preview-coupon-details job-marketing-flyers__preview-coupon-details--advancepay">
                  <h4>
                    <strong>
                      {job.advancepay_offer.offer_type === 'shipping'
                        ? 'Get FREE SHIPPING '
                        : `Save ${
                            job.advancepay_offer.offer_type === 'fixed_amount'
                              ? `$${(job.advancepay_offer.fixed_amount_cents / 100).toFixed(2)}`
                              : ` ${job.advancepay_offer.fixed_percentage || 0}%`
                          } ${job.advancepay_offer.includes_free_shipping ? '+ FREE SHIPPING ' : ''}`}
                      by purchasing AdvancePay credit
                    </strong>
                  </h4>

                  <p>
                    Offer details:
                    {job.advancepay_offer.max_redemptions && ` Valid for the first ${job.advancepay_offer.max_redemptions} customers`}
                    {job.advancepay_offer.max_redemptions && job.advancepay_offer.min_order_price_cents ? ' • ' : '.'}{' '}
                    {job.advancepay_offer.min_order_price_cents && `Orders must be $${(job.advancepay_offer.min_order_price_cents / 100).toFixed(2)} or more.`}
                  </p>

                  <p>After you purchase an AdvancePay credit, you will receive a text with the coupon code for this AdvancePay offer.</p>

                  {job.advancepay_offer.expiration && (
                    <p>
                      Expires <span className="font-semibold">{mediumDate(job.advancepay_offer.expiration)} </span> at 11:59pm PST.
                    </p>
                  )}
                </div>
              </article>
            )}

            <section className="job-marketing-flyers__preview-order">
              <h3>How to Order</h3>
              <p className="job-marketing-flyers__preview-paragraph">Ordering has never been easier or more convenient.</p>
              <p className="job-marketing-flyers__preview-paragraph">
                Check out this{' '}
                <a href="https://www.photoday.io/how_to_photoday" target="_blank" rel="noopener noreferrer">
                  step-by-step guide
                </a>
                .
              </p>
            </section>

            <footer className="job-marketing-flyers__preview-footer">
              {job.meta.studio_logo && (
                <figure className="job-marketing-flyers__preview-logo">
                  <img className="job-marketing-flyers__preview-logo-image" src={job.meta.studio_logo} alt={job.meta.studio_name} />
                </figure>
              )}
              <p className="job-marketing-flyers__preview-paragraph">
                Photos by {job.studio_name}{' '}
                <a href={`https://${job.meta.studio_website_url}`} target="_blank" rel="noopener noreferrer">
                  {job.meta.studio_website_url}
                </a>{' '}
                - <a href={`mailto:${job.meta.studio_contact_email}`}>{job.meta.studio_contact_email}</a>
              </p>
              <p className="job-marketing-flyers__preview-photoday"> Powered by PhotoDay.com</p>
            </footer>
          </div>
        </section>
      )}
    </>
  );
};

export default Preview;
