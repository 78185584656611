import { memo } from 'react';

// Plugins
import moment from 'moment';

// Helpers
import { normalizePhone } from '@/utils/formatters';
import imageScaling from '@/utils/imageScaling';

// Styles
import './style.css';

// Important Notes
//  This component is used to generate a pdf on the server and act ast the template for html email, because of this, semantic html5 elements and many css properties cannot be used i.e. <figure>, <article>, flexbox, grid, etc. The current cli for PDF service is wkhtmltopdf. Also, the markup and styles are being extracted and sent to the server when the flyer is updated. The extractCSS tool used in the Design component, will extract only single class selectors and remove all spaces, so styles cannot be written in short form like border: 1px solid #f4f4f4; This must be written in long form as border-width: 1px; border-style: solid; border-color: #f4f4f4; Otherwise, this style will not be readable on the server.

const GALLERY_URL = import.meta.env.VITE_GALLERY_URL;
const MY_PHOTODAY_URL = import.meta.env.VITE_MY_PHOTODAY_URL;

const LayoutClassic = memo(function ({ layoutRef, job, flyer }) {
  const isPrivateJob = job.access_mode === 'access_per_subject';
  const accessCode = isPrivateJob ? '{{access_code}}' : job.access_code;
  const galleryUrl = job.gallery_v4 ? `${MY_PHOTODAY_URL}g/${accessCode}` : GALLERY_URL;

  return (
    <div className="animate" ref={layoutRef}>
      <table cellPadding="0" cellSpacing="0" border="0" width="100%">
        <tbody>
          <tr>
            <td align="center">
              <table className="job-marketing-flyers__classic-template" cellPadding="0" cellSpacing="0" border="0" align="center" width="100%">
                <tbody>
                  {isPrivateJob && (
                    <tr>
                      <td align="right">
                        <span className="job-marketing-flyers__classic-sorting">{'{{sorting_data}}'}</span>
                      </td>
                    </tr>
                  )}
                  {flyer.show_job_logo && job.logo_url && (
                    <tr>
                      <td align="center">
                        <table align="center" width="100%">
                          <tbody>
                            <tr>
                              <td align="center">
                                <div className="job-marketing-flyers__classic-header">
                                  <img
                                    className="job-marketing-flyers__classic-header-logo"
                                    src={imageScaling({ url: job.logo_url, size: 'small' })}
                                    alt={job.name}
                                    height="40"
                                    style={{ margin: '0 auto' }}
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      <table className="job-marketing-flyers__classic-body" align="center" width="100%">
                        <tbody>
                          <tr>
                            {flyer.show_image && (
                              <td align="center">
                                {!isPrivateJob && !flyer.show_job_logo && <div className="job-marketing-flyers__classic-body--tall"></div>}

                                <div
                                  className={`job-marketing-flyers__classic-figure animate ${
                                    isPrivateJob && flyer.show_image && !flyer.image_url ? 'job-marketing-flyers__classic-figure--private' : ''
                                  } ${flyer.image_url ? 'job-marketing-flyers__classic-figure--bg-none' : ''} ${
                                    !isPrivateJob && flyer.show_image && !flyer.image_url ? 'job--marketing-flyers__classic-figure--placeholder' : ''
                                  }`}
                                >
                                  {isPrivateJob && flyer.show_image && !flyer.image_url && (
                                    <img className="job-marketing-flyers__classic-image" src="{{subject_image}}" alt="" />
                                  )}

                                  {flyer.show_image && flyer.image_url && (
                                    <img
                                      className="job-marketing-flyers__classic-image"
                                      src={imageScaling({ url: flyer.image_url, size: 'large' })}
                                      alt={flyer.image_filename}
                                    />
                                  )}
                                </div>
                              </td>
                            )}
                          </tr>
                          <tr>
                            <td>
                              <table align="center" width="100%">
                                <tbody>
                                  <tr>
                                    <td>
                                      <div className="job-marketing-flyers__classic-details">
                                        <h1 className="job-marketing-flyers__classic-title">{flyer.name}</h1>
                                        {flyer.show_job_date && (
                                          <p className="animate">
                                            {`Your picture date ${flyer.flyer_offer_type?.match(/pre_picture|advance_pay/) ? 'is' : 'was'} on`}{' '}
                                            <b>{moment.utc(job.date).format('L')}</b>
                                          </p>
                                        )}
                                        <div className="job-marketing-flyers__classic-divider"></div>
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>
                                      <div
                                        className={`job-marketing-flyers__classic-body-main ${
                                          !flyer.show_job_date ? 'job-marketing-flyers__classic-body-main--tall' : ''
                                        }`}
                                      >
                                        {flyer.body}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td cellPadding="10" cellSpacing="20">
                      <table align="center" width="100%">
                        <tbody>
                          <tr>
                            <td>
                              <div className="job-marketing-flyers__classic-instructions">
                                <h2 className="job-marketing-flyers__classic-title">
                                  {flyer.flyer_offer_type === 'pre_picture' &&
                                    `Get Updates About ${isPrivateJob ? "{{subject_name}}'s Photos for" : ''} ${job.name}`}
                                  {flyer.flyer_offer_type === 'advance_pay' &&
                                    `How to Purchase AdvancePay Credits for ${isPrivateJob ? "{{subject_name}}'s" : ''} ${job.name} Photos`}
                                  {flyer.flyer_offer_type?.match(/published|post_picture/) &&
                                    `How to View and Purchase ${isPrivateJob ? "{{subject_name}}'s" : ''} ${job.name} Photos`}
                                </h2>
                                <p>
                                  Text the access code{' '}
                                  <span className="job-marketing-flyers__classic-access">{isPrivateJob ? '{{access_code}}' : job.access_code}</span> to{' '}
                                  <b>{job.access_phone && normalizePhone(job.access_phone)}</b> to receive access and ongoing alerts* or visit{' '}
                                  <a className="job-marketing-flyers__classic-instructions-link" href={galleryUrl} target="_blank" rel="noopener noreferrer">
                                    <b>{galleryUrl}</b>
                                  </a>{' '}
                                  on your phone or computer.
                                </p>
                                {isPrivateJob && (
                                  <>
                                    {flyer.flyer_offer_type === 'pre_picture' ? (
                                      <span>Share access to {'{{subject_name}}'}'s gallery by giving this code or link to others.</span>
                                    ) : (
                                      <span>Share this code or link to allow access to {'{{subject_name}}'}'s gallery.</span>
                                    )}
                                  </>
                                )}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div className="job-marketing-flyers__classic-divider"></div>
                    </td>
                  </tr>

                  {job.advancepay_offer && flyer.flyer_offer_type === 'advance_pay' ? (
                    <tr>
                      <td>
                        <table align="center" width="100%">
                          <tbody>
                            <tr>
                              <td>
                                <div className="job-marketing-flyers__classic-offers">
                                  {job.advancepay_offer.offer_type === 'shipping' ? (
                                    <h3>Get FREE SHIPPING by purchasing an AdvancePay credit</h3>
                                  ) : (
                                    <h3>
                                      Save
                                      {job.advancepay_offer.offer_type === 'fixed_amount'
                                        ? ` $${(job.advancepay_offer.fixed_amount_cents / 100).toFixed(2)}`
                                        : ` ${job.advancepay_offer.fixed_percentage}%`}{' '}
                                      {job.advancepay_offer.includes_free_shipping ? '+ FREE SHIPPING! ' : ''} by purchasing AdvancePay credit
                                    </h3>
                                  )}

                                  {(job.advancepay_offer.max_redemptions || job.advancepay_offer.min_order_price_cents) && (
                                    <>
                                      <div className="job-marketing-flyers__classic-offer-rules">
                                        Offer details:
                                        {job.advancepay_offer.max_redemptions && ` Valid for the first ${job.advancepay_offer.max_redemptions} customers`}
                                        {job.advancepay_offer.max_redemptions && job.advancepay_offer.min_order_price_cents ? ' • ' : '.'}
                                        {job.advancepay_offer.min_order_price_cents &&
                                          `Orders must be $${(job.advancepay_offer.min_order_price_cents / 100).toFixed(2)} or more.`}
                                      </div>
                                    </>
                                  )}
                                  {job.advancepay_offer.expiration && (
                                    <small>
                                      Expires <b>{moment(job.advancepay_offer.expiration).format('MMMM Do, YYYY')}</b> at <i>11:59pm PST</i>.
                                    </small>
                                  )}
                                  <small>
                                    After you purchase an AdvancePay credit, you will receive a text with the coupon code for this AdvancePay offer. To redeem
                                    your credit, visit the published gallery and log in using the same phone number used when purchasing it. At checkout, you’ll
                                    notice the credit and offer will automatically be applied.
                                  </small>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  ) : (
                    <>
                      {flyer.offers?.length > 0 ? (
                        <tr>
                          <td align="center">
                            {flyer.offers &&
                              flyer.offers.map((offer, index) => {
                                if (index > 1) {
                                  return null;
                                } else {
                                  return (
                                    <table align="center" width="100%" key={index}>
                                      <tbody>
                                        <tr>
                                          <td>
                                            <div className="job-marketing-flyers__classic-offers">
                                              {offer.offer_type === 'shipping' ? (
                                                <h3 className="job-marketing-flyers__classic-offer-title">Get FREE SHIPPING</h3>
                                              ) : (
                                                <h3 className="job-marketing-flyers__classic-offer-title">
                                                  Save
                                                  {offer.offer_type === 'fixed_amount'
                                                    ? ` $${(offer.fixed_amount_cents / 100).toFixed(2)}`
                                                    : ` ${offer.fixed_percentage}%`}{' '}
                                                  {offer.includes_free_shipping ? '+ FREE SHIPPING! ' : ''}
                                                </h3>
                                              )}
                                              <p>
                                                Use coupon code: <b className="job-marketing-flyers__classic-coupon-code">{offer.code}</b>
                                              </p>
                                              {(offer.max_redemptions || offer.min_order_price_cents || offer.expiration) && (
                                                <>
                                                  <div className="job-marketing-flyers__classic-offer-rules">
                                                    {offer.max_redemptions && `Valid for the first ${offer.max_redemptions} customers. `}
                                                    {offer.min_order_price_cents &&
                                                      `Orders must be $${(offer.min_order_price_cents / 100).toFixed(2)} or more. `}
                                                    {offer.expiration && (
                                                      <>
                                                        Expires <b>{moment(offer.expiration).format('MMMM Do, YYYY')}</b> at <i>11:59pm PST</i>.
                                                      </>
                                                    )}
                                                  </div>
                                                </>
                                              )}
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  );
                                }
                              })}
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td>
                            <div className="job-marketing-flyers__classic-offers"></div>
                          </td>
                        </tr>
                      )}
                    </>
                  )}

                  {job.meta && (
                    <tr>
                      <td>
                        <table align="center" width="100%">
                          <tbody>
                            <tr>
                              <td align="center" valign="middle">
                                <div className="job-marketing-flyers__classic-meta">
                                  {job.meta.studio_logo && flyer.show_studio_logo && (
                                    <span className="animate job-marketing-flyers__classic-logo">
                                      <img src={imageScaling({ url: job.meta.studio_logo, size: 'small' })} alt={job.meta.studio_name} height="24" />
                                    </span>
                                  )}
                                  <h2>Photos by {job.meta.studio_name}</h2>
                                  <span>{job.meta.studio_contact_email}</span>
                                  <br />
                                  <span>{job.meta.studio_website_url}</span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}

                  {/* Disclaimer */}
                  <tr>
                    <td>
                      <table align="center" width="100%">
                        <tbody>
                          <tr>
                            <td align="center" valign="middle">
                              <div className="job-marketing-flyers__classic-disclaimer">
                                <span>*Data and message rates may apply. Message frequency may vary. Text HELP for assistance.</span>
                                <br />
                                <span>
                                  Text Stop to opt-out. Visit{' '}
                                  <a
                                    className="job-marketing-flyers__classic-instructions-link"
                                    href="https://www.photoday.com/customer-terms"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    https://www.photoday.com/customer-terms
                                  </a>{' '}
                                  for our Terms of <br /> Service and Privacy Policy.
                                </span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
});

export default LayoutClassic;
