import { useState, useEffect, useRef } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { createBackgroundsCollection, updateBackgroundsCollection, getBackgroundsCollection } from '../actions';

// Plugins
import { isEqual } from 'lodash';
import Dropzone from 'react-dropzone';

// Helpers
import imageScaling from '@/utils/imageScaling';

// Images
import imageBackgroundsDropdown from '@/assets/images/image-backgrounds-dropdown.png';

const BACKGROUND_MAX_COUNT = 12;

const CollectionManager = ({ selectCollection, onManageBackgroundsToggle }) => {
  const dropzoneRef = useRef();

  const dispatch = useDispatch();

  const { requesting } = useSelector((state) => state.backgrounds);

  const [currentCollection, setCurrentCollection] = useState({});
  const [collectionName, setCollectionName] = useState('');

  const [backgrounds, setBackgrounds] = useState([]);
  const [initialBackgrounds, setInitialBackgrounds] = useState([]);
  const [backgroundSelected, setBackgroundSelected] = useState({});

  const [showViewLarger, setShowViewLarger] = useState(false);
  const [showSaving, setShowSaving] = useState(false);

  const [dropzoneIsActive, setDropZoneIsActive] = useState(false);
  const [dropRejected, setDropRejected] = useState(false);

  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [dropRejectedFiles, setDropRejectedFiles] = useState([]);

  const collectionChanged = !(isEqual(initialBackgrounds, backgrounds) && currentCollection.name === collectionName);

  const handleDropAccepted = (files) => {
    const currentBackgrounds = [...backgrounds].filter((bg) => !bg._destroy);
    const acceptCount = BACKGROUND_MAX_COUNT - currentBackgrounds.length;

    const newBackgrounds = [];
    const rejected = [];

    const promises = [];

    files.forEach((file, index) =>
      promises.push(
        new Promise((resolve) => {
          const reader = new FileReader();

          reader.readAsDataURL(file);

          reader.onload = () => {
            const image = new Image();

            image.src = reader.result;

            image.onload = () => {
              if ((image.width === 2400 && image.height === 3000) || (image.width === 3000 && image.height === 2400)) {
                newBackgrounds.push({
                  id: Date.now().toString() + index,
                  content: image.src,
                  filename: file.name,
                  content_type: file.type
                });
              } else {
                rejected.push(file.name);
              }

              resolve();
            };
          };
        })
      )
    );

    Promise.all(promises).then(() => {
      if (newBackgrounds.length > acceptCount) {
        newBackgrounds.slice(acceptCount).forEach((bg) => rejected.push(bg.filename));
        newBackgrounds.splice(acceptCount);
      }

      if (newBackgrounds.length > 0) {
        setBackgroundSelected(newBackgrounds[0]);
        setBackgrounds([...backgrounds, ...newBackgrounds]);
      }

      if (rejected.length > 0) {
        setRejectedFiles([...rejectedFiles, ...rejected]);
      }
    });
  };

  const handleDropRejected = (files) => {
    const rejected = files.map((file) => file.name);

    setDropRejectedFiles([...dropRejectedFiles, ...rejected]);
    setDropZoneIsActive(false);
  };

  const handleDropRejectedToggle = () => {
    setDropRejected(!dropRejected);
    setRejectedFiles([]);
    setDropRejectedFiles([]);
  };

  const handleDialogOpen = () => {
    if (dropzoneRef.current && typeof dropzoneRef.current.open === 'function') {
      dropzoneRef.current.open();
    }
  };

  const handleDragEnter = () => {
    setDropZoneIsActive(true);
  };

  const handleDragLeave = () => {
    setDropZoneIsActive(false);
  };

  const handleBackgroundSelect = (e, background) => {
    e.stopPropagation();
    setBackgroundSelected(background);
  };

  const handleBackgroundRemove = (e, backgroundId) => {
    e.stopPropagation();
    setBackgroundSelected({});
    setBackgrounds(backgrounds.map((background) => (background.id === backgroundId ? { ...background, _destroy: true } : background)));
  };

  const handleCollectionSave = () => {
    const newCollection = {
      id: currentCollection.id,
      name: collectionName,
      backgrounds_attributes: backgrounds
        .filter((background) => (background?.content ? !background._destroy : true))
        .map((background) =>
          background.content
            ? {
                image_attachment: {
                  content: background.content,
                  content_type: background.content_type,
                  filename: background.filename
                }
              }
            : background
        )
    };

    if (currentCollection?.id) {
      dispatch(
        updateBackgroundsCollection(newCollection, ({ status }) => {
          // Deal with server errors
          if (status !== 200) {
            setShowSaving(false);
          } else {
            onManageBackgroundsToggle();
          }
        })
      );
    } else {
      dispatch(
        createBackgroundsCollection(newCollection, ({ status }) => {
          // Deal with server errors
          if (status !== 200) {
            setShowSaving(false);
          } else {
            onManageBackgroundsToggle();
          }
        })
      );
    }

    setShowSaving(true);
  };

  const DropRejected = () => (
    <div className="flex items-center justify-center flex-nowrap flex-col animate collection-manager__background-overlay">
      <h3>{rejectedFiles.length > 1 ? 'Files' : 'File'} not accepted</h3>

      <div className="collection-manager__background-overlay-section">
        {rejectedFiles
          .concat(dropRejectedFiles)
          .filter((_, index) => index <= 4)
          .map((file, index) => (
            <p className="text-body-sm text-left truncate" key={index}>
              {file}
            </p>
          ))}

        {rejectedFiles.length + dropRejectedFiles.length > 5 && (
          <p className="text-body-sm text-left truncate">{`+ ${rejectedFiles.length + dropRejectedFiles.length - 5} more.`}</p>
        )}
      </div>

      <h3>Requirements</h3>
      <div className="collection-manager__background-overlay-section">
        <p className="text-body-xs text-left">Backgrounds format must be JPG and 15MB or less.</p>
        <p className="text-body-xs text-left">Collections may contain up to 12 backgrounds.</p>
        <p className="text-body-xs text-left">Background dimension must be either 2400x3000 pixels or 3000x2400 pixels.</p>
      </div>

      <button className="button button--outline" type="button" onClick={handleDropRejectedToggle}>
        Continue
      </button>
    </div>
  );

  useEffect(() => {
    if (selectCollection?.id) {
      dispatch(
        getBackgroundsCollection({ collectionId: selectCollection.id }, ({ data }) => {
          setCurrentCollection(data);
          setCollectionName(data.name);
          setBackgrounds(data.backgrounds);
          setInitialBackgrounds(data.backgrounds);
        })
      );
    }
  }, []);

  useEffect(() => {
    if (rejectedFiles.length > 0 || dropRejectedFiles.length > 0) {
      setDropRejected(true);
    }
  }, [rejectedFiles, dropRejectedFiles]);

  return (
    <aside className="modal animate collection-manager__background">
      <div className="modal__box modal__box modal__box--secondary">
        <button className="button button--action modal__close" name="close" type="button" onClick={onManageBackgroundsToggle}>
          <i className="icon-close"></i>
        </button>

        <main className="flex modal__content">
          <section className="flex basis-5/12 md:basis-full flex-col justify-between flex-nowrap modal__content-section modal__content-section--dark">
            <header>
              <h2 className="text-headline-xs mb-4">{currentCollection?.id ? 'Edit Collection Name' : 'Add Background Collection'}</h2>
              <input
                className="input--block mb-2.5"
                type="text"
                name="collection"
                value={collectionName}
                onChange={({ target }) => setCollectionName(target.value)}
                maxLength="50"
                placeholder="Collection Name"
                required
              />
            </header>
            <Dropzone
              ref={dropzoneRef}
              noClick={true}
              multiple={true}
              noKeyboard={true}
              maxSize={15000000}
              disabled={dropRejected}
              noDragEventsBubbling={true}
              preventDropOnDocument={true}
              useFsAccessApi={false}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              accept={{ 'image/*': ['.jpeg', '.jpg'] }}
              onDropAccepted={handleDropAccepted}
              onDropRejected={handleDropRejected}
            >
              {({ getRootProps, getInputProps }) => (
                <>
                  <input {...getInputProps()} />
                  {backgrounds.filter((background) => !background._destroy).length > 0 ? (
                    <div
                      className={`flex flex-col justify-between flex-nowrap animate collection-manager__background-container ${
                        dropzoneIsActive ? 'collection-manager__background-container--active' : ''
                      }`}
                      {...getRootProps()}
                    >
                      <ul className="storefront-backgrounds__list">
                        {backgrounds
                          .filter((background) => !background._destroy)
                          .map((background) => (
                            <li
                              key={background.id}
                              className={`flex items-center justify-between storefront-backgrounds__item ${
                                backgroundSelected.id === background.id ? 'storefront-backgrounds__item--active' : ''
                              }`}
                              onClick={(e) => handleBackgroundSelect(e, background)}
                            >
                              <img
                                src={
                                  background.image_url
                                    ? imageScaling({
                                        url: background.image_url,
                                        size: 'small'
                                      })
                                    : background.content
                                }
                                width="50"
                                alt={background.name}
                              />

                              <button className="button button--lean" name="remove" type="button" onClick={(e) => handleBackgroundRemove(e, background.id)}>
                                Remove
                              </button>
                            </li>
                          ))}
                      </ul>
                      <small className="text-center text-body-xs">
                        Drag additional background to add or{' '}
                        <button className="button button--link" onClick={handleDialogOpen}>
                          upload here
                        </button>
                      </small>

                      {dropRejected && <DropRejected />}
                    </div>
                  ) : (
                    <div
                      className={`flex flex-col items-center justify-center collection-manager__background-container ${
                        dropzoneIsActive ? 'collection-manager__background-container--active' : ''
                      }`}
                      {...getRootProps()}
                    >
                      <img src={imageBackgroundsDropdown} alt="Drag background" width="38" draggable="false" />
                      <small>
                        Drag background{' '}
                        <button className="button button--link font-semibold" onClick={handleDialogOpen}>
                          here
                        </button>{' '}
                        or upload{' '}
                        <button className="button button--link font-semibold" onClick={handleDialogOpen}>
                          file
                        </button>
                      </small>
                      <small className="text-body-xs">We accept designs in JPG format that are 15MB or less.</small>

                      {dropRejected && <DropRejected />}
                    </div>
                  )}
                </>
              )}
            </Dropzone>
            <footer className="text-center">
              <hr className="hr--short" />
              <small className="text-body-xs">
                Need help? View the{' '}
                <a
                  href="https://support.photoday.io/en/articles/4873011-getting-started-with-photoday-s-backgrounds-feature"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  support article on backgrounds
                </a>
                .
              </small>
            </footer>
          </section>
          <section className="flex basis-7/12 md:basis-full flex-col justify-between modal__content-section">
            <h3 className="text-headline-xs">Preview Background</h3>
            {backgroundSelected?.image_url || backgroundSelected?.content ? (
              <figure className="flex flex-col items-center collection-manager__background-preview">
                <img
                  className="collection-manager__background-preview-image"
                  src={
                    backgroundSelected.image_url
                      ? imageScaling({
                          url: backgroundSelected.image_url,
                          size: 'large'
                        })
                      : backgroundSelected.content
                  }
                  alt={backgroundSelected.name}
                />
                <button className="button button--link" name="view" type="button" onClick={() => setShowViewLarger(true)}>
                  <small>View Larger</small>
                </button>
              </figure>
            ) : (
              <figure className="flex justify-center items-center collection-manager__background-preview">
                <small className="text-center">Select a background to preview it.</small>
              </figure>
            )}
            <footer className="text-center">
              <button
                className="button button--medium"
                name="save"
                type="button"
                data-loading={requesting}
                disabled={!collectionName || requesting || !collectionChanged}
                onClick={handleCollectionSave}
              >
                Save
              </button>
            </footer>
          </section>
        </main>

        {/* View larger layer */}
        <aside
          className={`flex flex-col justify-center items-center modal__content storefront-backgrounds__modal-overlay ${showViewLarger ? '' : 'transparent'}`}
        >
          <button className="button button--action modal__close" name="close" type="button" onClick={() => setShowViewLarger(false)}>
            <i className="icon-close"></i>
          </button>

          <figure className="storefront-backgrounds__view">
            <img
              className="storefront-backgrounds__view-image"
              src={
                backgroundSelected.image_url
                  ? imageScaling({
                      url: backgroundSelected.image_url,
                      size: 'large'
                    })
                  : backgroundSelected.content
              }
              alt={backgroundSelected.name}
            />
          </figure>
        </aside>

        {/* Loader View */}
        <aside className={`flex flex-col justify-center items-center storefront-backgrounds__modal-overlay ${showSaving ? '' : 'transparent'}`}>
          <div className="flex items-center justify-center flex-col">
            <p className="mb-5">{currentCollection?.id ? 'Updating' : 'Saving'} Background Collection</p>
            <br />
            <br />
            <button className="button button--medium" type="button" disabled={requesting} data-loading-dark={requesting}>
              Save
            </button>
          </div>
        </aside>
      </div>
    </aside>
  );
};

export default CollectionManager;
