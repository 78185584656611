import { useState, useEffect, useRef } from 'react';

// Plugins
import { isEqual } from 'lodash';

const PreviewOverlay = function ({ previewList, layoutId = '', themeImage = '', styleSelected, logoShow, productSelected, onSaveThumbnail }) {
  const canvasRef = useRef();

  const fontFacesLoadedRef = useRef([]);
  const [previewItem, setPreviewItem] = useState({});
  const [nodes, setNodes] = useState([]);

  const [textStyle, setTextStyle] = useState({
    textColor: 'rgba(255, 255, 255, 1)',
    strokeColor: 'rgba(0, 0, 0, 1)',
    strokeWidth: 2
  });

  const background = new Image();
  background.setAttribute('crossorigin', 'anonymous');
  background.onload = () => {
    canvasDraw();
  };

  const thumbnail = new Image();
  thumbnail.setAttribute('crossorigin', 'anonymous');
  thumbnail.onload = () => {
    onSaveThumbnail(thumbnail);
  };

  const setFallbackFonts = (font) => {
    switch (font) {
      case 'Impact':
        return 'Impact, Anton';
      case 'Arial':
        return 'Arial, Roboto';
      case 'Arial Bold':
        return 'Arial Bold, Roboto Bold';
      case 'Arial Black':
        return 'Arial black, Roboto Black';
      case 'Courier New':
        return 'Courier New, Courier Prime';
      default:
        return font;
    }
  };

  const fontFaceSet = document.fonts;

  if (fontFaceSet && fontFaceSet.addEventListener) {
    fontFaceSet.addEventListener('loadingdone', function (event) {
      if (!isEqual(fontFacesLoadedRef.current, event.fontfaces)) {
        fontFacesLoadedRef.current = event.fontfaces;
        preLoadBackground();
      }
    });
  }

  const canvasDraw = () => {
    const canvas = canvasRef.current;

    if (canvas) {
      const ctx = canvas.getContext('2d');
      canvas.width = productSelected.expectedWidth;
      canvas.height = productSelected.expectedHeight;

      ctx.clearRect(0, 0, productSelected.expectedWidth, productSelected.expectedHeight);
      const layers = [];

      nodes.forEach((node) => {
        const layer = document.createElement('canvas');
        const layerCtx = layer.getContext('2d');

        layer.width = productSelected.expectedWidth;
        layer.height = productSelected.expectedHeight;

        if (node.preview.theme_image) {
          // draw custom theme
          layerCtx.drawImage(background, 0, 0, productSelected.expectedWidth, productSelected.expectedHeight);
        }

        if (node.preview.node_type === 'image' && !node.preview.theme_image && node.preview['node-name'] !== 'logo' && node.preview['node-name'] !== 'Frames') {
          // Draw image nodes placeholders
          layerCtx.fillStyle = '#d6d6d6'; // inner fill
          layerCtx.fillRect(node.preview.location.x, node.preview.location.y, node.preview.location.width, node.preview.location.height);

          if (node.preview?.outline) {
            layerCtx.strokeStyle = node.preview['outline-color'];
            layerCtx.lineWidth = node.preview['outline-width'];
            layerCtx.strokeRect(
              node.preview.location.x + parseInt(node.preview['outline-offset'], 10),
              node.preview.location.y + parseInt(node.preview['outline-offset'], 10),
              node.preview.location.width - parseInt(node.preview['outline-offset'], 10),
              node.preview.location.height - parseInt(node.preview['outline-offset'], 10)
            );
          }
        }

        if (logoShow && node.preview['node-name'] === 'logo') {
          // Draw image nodes
          layerCtx.fillStyle = '#d6d6d6';
          layerCtx.fillRect(node.preview.location.x, node.preview.location.y, node.preview.location.width, node.preview.location.height);

          if (node.preview?.outline) {
            layerCtx.strokeStyle = node.preview['outline-color'];
            layerCtx.lineWidth = node.preview['outline-width'];
            layerCtx.strokeRect(
              node.preview.location.x + parseInt(node.preview['outline-offset'], 10),
              node.preview.location.y + parseInt(node.preview['outline-offset'], 10),
              node.preview.location.width - parseInt(node.preview['outline-offset'], 10),
              node.preview.location.height - parseInt(node.preview['outline-offset'], 10)
            );
          }
        }

        if (node.preview.node_type === 'text') {
          // draw text
          const font = setFallbackFonts(node.preview['font-family']);
          const fontWeight = node.preview['font-weight'] || 'normal';
          let fontHeight = node.preview.location.height;

          layerCtx.fillStyle = textStyle.textColor;
          layerCtx.font = `${fontWeight} ${fontHeight}px ${font}`;
          layerCtx.textAlign = 'center';

          const textWidth = layerCtx.measureText(node.preview.text).width;
          const nodeWidth = node.preview.location.width;

          if (textWidth > nodeWidth) {
            const factor = textWidth / nodeWidth;
            const newFontHeight = Math.floor(fontHeight / factor);
            layerCtx.font = `${fontWeight} ${fontHeight}px ${font}`;
          }

          if (textStyle.strokeWidth) {
            layerCtx.strokeStyle = textStyle.strokeColor;
            layerCtx.lineWidth = textStyle.strokeWidth;

            layerCtx.strokeText(
              node.preview.text,
              node.preview.location.x + node.preview.location.width / 2,
              node.preview.location.y + node.preview.location.height,
              node.preview.location.width
            );
          }

          layerCtx.fillText(
            node.preview.text,
            node.preview.location.x + node.preview.location.width / 2,
            node.preview.location.y + node.preview.location.height,
            node.preview.location.width
          );
        }

        layers.push({ layer, zIndex: node.preview['z-index'] });
      });

      // Draw each layer based on zIndex
      layers.sort((a, b) => a.zIndex - b.zIndex);
      layers.forEach((layer) => {
        ctx.drawImage(layer.layer, 0, 0, productSelected.expectedWidth, productSelected.expectedHeight);
      });

      thumbnail.src = canvas.toDataURL('image/jpeg');
    }
  };

  const preLoadBackground = () => {
    const themeNode = nodes.find((node) => node.theme_image);

    if (themeNode) {
      background.src = themeNode.theme_image;
    }
  };

  useEffect(() => {
    const foundItem = previewList.find((item) => item.layout_id === layoutId);
    if (foundItem) {
      setPreviewItem(foundItem);
    }
  }, [previewList]);

  useEffect(() => {
    if (Object.keys(previewItem).length > 0) {
      const previewNodes = previewItem.nodes;

      // Create Sample Data
      const mergedNodes = previewNodes.map((previewNode) => {
        let newNode;

        if (previewNode.theme_image) {
          newNode = Object.assign({}, { id: Date.now().toString(), theme_image: themeImage, theme_name: 'theme name', preview: previewNode });
        } else {
          let newPreviewNode;

          // Parse text nodes
          if (previewNode.node_type === 'text') {
            let text = previewNode.node_description || previewNode.node_key;

            if (text === 'Name') text = 'First & Last Name';

            newPreviewNode = Object.assign({}, previewNode, { text });
          } else {
            newPreviewNode = previewNode;
          }

          newNode = Object.assign({}, { id: Date.now().toString(), preview: newPreviewNode });
        }

        return newNode;
      });

      setNodes(mergedNodes);
    }
  }, [previewItem, themeImage]);

  useEffect(() => {
    if (nodes.length > 0) {
      preLoadBackground();
    }
  }, [nodes, logoShow, textStyle]);

  useEffect(() => {
    switch (styleSelected.value) {
      case 'white_no_stroke':
        setTextStyle({
          textColor: 'rgba(255, 255, 255, 1)',
          strokeColor: 'rgba(0, 0, 0, 1)',
          strokeWidth: 0
        });
        break;
      case 'white_with_stroke':
        setTextStyle({
          textColor: 'rgba(255, 255, 255, 1)',
          strokeColor: 'rgba(0, 0, 0, 1)',
          strokeWidth: 2
        });
        break;

      case 'black_no_stroke':
        setTextStyle({
          textColor: 'rgba(0, 0, 0, 1)',
          strokeColor: 'rgba(255, 255, 255, 1)',
          strokeWidth: 0
        });
        break;
      case 'black_with_stroke':
        setTextStyle({
          textColor: 'rgba(0, 0, 0, 1)',
          strokeColor: 'rgba(255, 255, 255, 1)',
          strokeWidth: 2
        });
        break;

      default:
        setTextStyle({
          textColor: 'rgba(255, 255, 255, 1)',
          strokeColor: 'rgba(0, 0, 0, 1)',
          strokeWidth: 2
        });
    }
  }, [styleSelected]);

  return (
    <figure className="collection-manager-preview__figure">
      {Object.keys(previewItem).length > 0 ? (
        <canvas ref={canvasRef} className="animate collection-manager-preview__canvas"></canvas>
      ) : (
        <>
          <img src={themeImage} className="animate collection-manager-preview__not-found" alt="theme background" />
          <figcaption className="font-semibold">No preview available</figcaption>
        </>
      )}
    </figure>
  );
};

export default PreviewOverlay;
