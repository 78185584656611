import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Redirect, Link, useLocation } from 'react-router-dom';

// Plugins
import queryString from 'query-string';
import { ToastContainer } from 'react-toastify';

// Redux
import { formValueSelector, change, autofill } from 'redux-form';
import { logoutRequest } from '@/components/Login/actions';
import { addressValidate } from '@/components/Shared/AddressVerification/actions';
import { setStudioInformation, createStudioRequest, setRegisterStage } from './actions';

// Components
import Verify from './Verify';
import FbPixel from '@/components/SignUp/Shared/FbPixel';
import Container from '@/components/SignUp/Shared/Container';
import RegisterForm from './Forms/RegisterForm';
import RegisterFormStudio from './Forms/RegisterFormStudio';
import CreateAccountForm from './Forms/CreateAccountForm';

// Helpers
import * as Stages from './constants';
import { stringSpecialCharactersValidator, phoneNumberValidator } from '@/utils/validateHelpers';

const Register = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const selector = formValueSelector('RegisterFormStudio');
  const line1 = useSelector((state) => selector(state, 'studio.address_attributes.line1'));
  const zip = useSelector((state) => selector(state, 'studio.address_attributes.zip'));
  const policies_agreed = useSelector((state) => formValueSelector('CreateAccountForm')(state, 'user.policies_agreed'));
  const signUpState = useSelector((state) => state.signUp);

  const [localState, setLocalState] = useState({
    state: '',
    addressDeliverability: '',
    isStudioNameValid: true,
    isPhoneNumberValid: true,
    containerMessages: { title: 'Hi There', subtitle: "You're just seconds away from using PhotoDay!" }
  });

  const onSubmitInformation = (values) => {
    const { lab } = props;
    const { state } = localState;

    const { user: valuesUser, studio: valuesStudio, meta: valuesMeta } = values;
    const { user: signUpUser, studio: signUpStudio, meta: signUpMeta } = signUpState;

    const user = valuesUser?.first_name ? valuesUser : signUpUser;
    const studio = valuesStudio?.name ? valuesStudio : signUpStudio;
    const meta = valuesMeta?.hear_about_us ? valuesMeta : signUpMeta;

    const addressAttributes = studio ? studio.address_attributes : {};

    dispatch(
      setStudioInformation({
        user: {
          ...user,
          ...(queryParams.utm_source ? { utm_source: queryParams.utm_source } : {}),
          ...(queryParams.utm_medium ? { utm_medium: queryParams.utm_medium } : {}),
          ...(queryParams.utm_campaign ? { utm_campaign: queryParams.utm_campaign } : {}),
          ...(queryParams.utm_term ? { utm_term: queryParams.utm_term } : {}),
          ...(queryParams.utm_content ? { utm_content: queryParams.utm_content } : {})
        },
        studio: {
          ...studio,
          address_attributes: {
            ...addressAttributes,
            state
          }
        },
        meta,
        lab
      })
    );
  };

  const onSubmitCreate = (values) => {
    const { lab } = props;
    const { user: valuesUser } = values;
    const { studio, user: signUpUser, meta } = signUpState;

    // User values
    const user = { ...signUpUser, email: valuesUser.email ?? signUpUser.email, password: valuesUser.password, policies_agreed: valuesUser.policies_agreed };

    // Meta values
    const subjectsPerYear = meta?.subjects_per_year?.label;

    let typeOfPhotography = '';
    meta?.types_of_photography?.map((type) => (typeOfPhotography += `${type.label}, `));

    const hearAboutUs = meta?.hear_about_us?.label;
    const hearAboutUsSpecify = meta?.hear_about_us_specify;
    const yearsInBusiness = meta?.years_in_business?.label;
    const nextPictureDay = meta?.next_picture_day?.label;

    dispatch(
      createStudioRequest({
        user,
        studio: {
          ...studio,
          meta: {
            subjects_photographed: subjectsPerYear,
            hear_about_us: hearAboutUs,
            hear_about_us_specify: hearAboutUsSpecify,
            photography_type: typeOfPhotography,
            years_in_business: yearsInBusiness,
            next_picture_day: nextPictureDay
          }
        },
        lab
      })
    );
  };

  const previousStage = () => {
    const { lab } = props;
    const { registerStage } = signUpState;
    const firstStage = Stages.STAGE_MAPPINGS[lab.key].first;

    dispatch(setRegisterStage(registerStage - 1 > firstStage ? registerStage - 1 : firstStage));
  };

  const setContainerMessage = (stage) => {
    switch (stage) {
      case 1:
        return setLocalState((prevState) => ({
          ...prevState,
          containerMessages: { title: 'It is all about you!', subtitle: 'Tell us a little about you and your photography biz.' }
        }));
      case 2:
        return setLocalState((prevState) => ({ ...prevState, containerMessages: { title: 'Your studio', subtitle: "Now let's set up your studio." } }));
      case 3:
        return setLocalState((prevState) => ({
          ...prevState,
          containerMessages: { title: 'Almost done', subtitle: 'Create a unique password that only you will remember.' }
        }));
      case 4:
        return setLocalState((prevState) => ({
          ...prevState,
          containerMessages: { title: 'The last step!', subtitle: "You're so close! Let's verify your account." }
        }));
      default:
        return setLocalState((prevState) => ({
          ...prevState,
          containerMessages: { title: 'Hi There', subtitle: "You're just seconds away from using PhotoDay!" }
        }));
    }
  };

  const handleStateDDLOnChange = (val) => {
    if (val) {
      setLocalState((prevState) => ({ ...prevState, state: val.value }));
    }
  };

  const handleUpdateStudioInformationClick = (e) => {
    e.preventDefault();

    previousStage();
  };

  const handleAddressValidate = () => {
    dispatch(
      addressValidate({ line1, zip }, (response) => {
        if (response) {
          dispatch(change('RegisterFormStudio', 'studio.address_attributes.city', response.components.city.toLowerCase()));
          setLocalState((prevState) => ({ ...prevState, state: response.components.state, addressDeliverability: response.deliverability }));
        } else {
          setLocalState((prevState) => ({ ...prevState, addressDeliverability: '' }));
        }
      })
    );
  };

  const handleStudioNameValidate = (evt) => {
    setLocalState((prevState) => ({ ...prevState, isStudioNameValid: !stringSpecialCharactersValidator(evt?.target?.value) }));
  };

  const handlePhoneNumberValidate = (evt) => {
    setLocalState((prevState) => ({ ...prevState, isPhoneNumberValid: !phoneNumberValidator(evt?.target?.value) }));
  };

  useEffect(() => {
    dispatch(logoutRequest());

    const { lab, registerStage } = props;

    // Go to first stage if stage is not declared
    if (lab.key && registerStage == null) {
      dispatch(setRegisterStage(Stages.STAGE_MAPPINGS[lab.key].first));
    }

    setContainerMessage(registerStage);
  }, []);

  useEffect(() => {
    setContainerMessage(signUpState.registerStage);
  }, [signUpState.registerStage]);

  useEffect(() => {
    if (signUpState.user?.email) {
      dispatch(autofill('CreateAccountForm', 'user.email', signUpState.user.email));
    }
  }, [signUpState.user?.email]);

  const { lab, isEmailDisabled, isAuthenticated } = props;
  const { user, registerStage, requesting } = signUpState;
  const { state, addressDeliverability, isStudioNameValid, isPhoneNumberValid, containerMessages } = localState;

  if (!lab || isAuthenticated) {
    return <Redirect to={'/'} />;
  }

  if (registerStage === Stages.REGISTER_STAGE_EMAIL_VERIFICATION_SENT && isEmailDisabled) {
    return <Redirect to={'/'} />;
  }

  if (registerStage === Stages.REGISTER_STAGE_EMAIL_VERIFICATION_SENT) {
    return <Verify lab={lab} email={user.email} title={containerMessages.title} subtitle={containerMessages.subtitle} />;
  }

  return (
    <>
      <FbPixel />
      <ToastContainer />

      <Container id="register" labImage={lab.image} title={containerMessages.title} subtitle={containerMessages.subtitle}>
        <div className={registerStage === Stages.REGISTER_STAGE_INFORMATION || Stages.REGISTER_STAGE_CONNECT ? '' : 'register-form--hidden'}>
          <RegisterForm registerStage={registerStage} onSubmit={(values) => onSubmitInformation(values)} initialValues={props} />
        </div>

        <div className={registerStage === Stages.REGISTER_STAGE_STUDIO_INFORMATION ? '' : 'register-form--hidden'}>
          <RegisterFormStudio
            state={state}
            registerStage={registerStage}
            initialValues={props}
            parentHandleChange={handleStateDDLOnChange}
            onPreviousStage={previousStage}
            addressDeliverability={addressDeliverability}
            onAddressValidate={handleAddressValidate}
            isStudioNameValid={isStudioNameValid}
            isPhoneNumberValid={isPhoneNumberValid}
            onStudioNameValidate={handleStudioNameValidate}
            onPhoneNumberValidate={handlePhoneNumberValidate}
            onSubmit={(values) => onSubmitInformation(values)}
          />
        </div>

        <div className={registerStage === Stages.REGISTER_STAGE_CREATE_ACCOUNT ? '' : 'register-form--hidden'}>
          <CreateAccountForm
            onSubmit={(values) => onSubmitCreate(values)}
            requesting={requesting}
            initialValues={props}
            policies_agreed={policies_agreed}
            disableEmail={!!isEmailDisabled}
            onPreviousStage={previousStage}
          />
          <div className="update-information-link-container">
            <span>or </span>
            <Link to="#" onClick={handleUpdateStudioInformationClick}>
              update your studio information
            </Link>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Register;
